.skill-list {
  max-width: 480px;
  margin: auto;
  padding-bottom: 3rem;
  padding-top: 1rem;
}
@media (min-width: 900px) {
  .skill-list {
    width: 80%;
    max-width: 900px;
  }
}
