.profile-content {
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 650px;
}

@media (min-width: 750px) {
  .profile-content {
    max-width: 70%;
  }
}
@media (min-width: 950px) {
  .profile-content {
    max-width: 800px;
  }
}
