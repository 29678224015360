.jumbotron-button-content {
  /* background-color: red; */
  -webkit-animation: MoveUpDown 1s linear infinite;
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@-webkit-keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 1rem;
  }
}
@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 1rem;
  }
}
