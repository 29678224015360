.checked-star {
  color: var(--secondary-heading-color);
}
.unchecked-star {
  color: #ccc;
}

.skill-item {
  display: block;
  padding: 0.5rem 3rem;
}
.skill-item__star-list {
  display: inline-block;
  text-align: right;
  width: 50%;
}
.skill-item h1 {
  font-size: 1.1rem;
  display: inline-block;
  margin: 0;
  width: 50%;
  color: var(--background-1-font-color);
}

/* 
.skill-item:hover {
  margin: 0rem 3rem;
} */

.skill-item:hover {
  padding-top: 0rem;
  /* padding-bottom: 0rem; */
}

.skill-item:hover h1 {
  font-size: 1.2rem;
  padding-top: 0;
  padding-bottom: 0rem;
}
.skill-item:hover .star {
  margin: 0 0.05rem;
}

@media (min-width: 900px) {
  .skill-item {
    display: inline-block;
    padding: 0.5rem 1rem;
    width: 45%;
  }
}
