.header {
  background: var(--header-bar-color);
  position: absolute;
  width: 100%;
  text-align: right;
  z-index: 1;
}

.header.fixed {
  position: fixed;
  top: 0;
}

@media (min-width: 900px) {
  .header {
    position: relative;
  }
}
