.item-card {
  padding: 1rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.bottom-border {
  border-bottom: 1px solid var(--border-color);
}

.item-card__company_name {
  font-size: 1rem;
  margin: 0;
  vertical-align: middle;
  margin-bottom: 0;
}

.item-card__duration {
  margin: 0;
  font-size: 0.7rem;
}

.item-card__duration_time {
  color: var(--font-color-grey);
  font-size: 0.8rem;
  margin: 0;
}

.item-card__role {
  font-size: 0.9rem;
  margin: 0;
}

.item-card__location {
  font-size: 0.9rem;
  margin: 0;
}

.expand-button {
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 0.3rem;
  width: 15%;
  border: 0;
  border-radius: 4px;
}

.expandable-card {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s 0s linear;
}

.expandable-card p {
  display: block;
  padding: 0rem;
}

.expand-card {
  max-height: 20rem;
}

.background-2-text {
  color: var(--background-2-font-color);
}

.background-1-text {
  color: var(--background-1-font-color);
}

@media (min-width: 750px) {
  .expand-button {
    display: none;
  }
  .expandable-card {
    max-height: 20rem;
    padding: 0;
    margin-top: 0.3rem;
  }
  .expandable-card p {
    padding: 0;
    font-size: 0.8rem;
    margin: 0;
  }
}

@media (min-width: 900px) {
  .heading-card {
    display: inline-block;
    width: 40%;
    margin-right: 5%;
    vertical-align: top;
  }
  .description-card {
    display: inline-block;
    width: 45%;
  }
}
