.profile-content__about {
  text-align: center;
  padding-top: 3rem;
}

.profile-content__about h1 {
  font-size: 1.8rem;
  color: var(--secondary-heading-color);
  font-weight: normal;
  margin: 0;
}
.profile-content__about p {
  font-size: 1rem;
  color: var(--font-color-grey);
  padding: 1rem;
}

@media (min-width: 900px) {
  .profile-content__about {
    display: inline-block;
    width: 37%;
    vertical-align: top;
    border-radius: 1px;
  }
}
