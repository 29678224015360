.scroll-card {
  position: absolute;
  width: 100%;
  text-align: center;
  max-height: 22rem;
  transition: max-height 0.5s;
  overflow: hidden;
}

.scroll-card div {
  background-color: var(--header-options-color);
  padding: 0.4rem;
}
.scroll-card div:hover h1 {
  font-weight: bold;
  color: var(--font-color-light-grey);
}
.scroll-card div:hover {
  cursor: pointer;
}

.scroll-card div.active {
  background-color: var(--header-options-highlight-color);
}

.scroll-card div h1 {
  margin: 0;
  font-weight: normal;
  color: var(--header-options-font-color);
  font-size: 1.2rem;
  padding: 0.2rem;
}
.hide {
  max-height: 0;
}

@media (min-width: 900px) {
  .scroll-card {
    position: absolute;
    top: 0;
    right: 0;
    width: 7rem;
    max-height: 28rem;
    margin-top: 0px;
  }

  .scroll-card.fixed {
    position: fixed;
    top: 5%;
  }

  .scroll-card div {
    padding: 1rem 0;
  }
  .scroll-card div h1 {
    font-size: 1rem;
    padding: 0.2rem;
  }

  .hide {
    max-height: 28rem;
  }
}
