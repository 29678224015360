.jumbotron h1 {
  padding-bottom: 1rem;
  display: block;
  margin: auto;
  margin-top: 0;
  width: 90%;
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  font-size: 1.5rem;
  font-style: normal;
  color: var(--background-2-font-color);
}

.jumbotron-content-links {
  /* text-decoration: underline; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  border-bottom: 1px solid var(--background-2-font-color);
}
.jumbotron-content-links:hover {
  cursor: pointer;
}

.jumbotron h2 {
  display: block;
  margin-top: 1rem;
  text-align: left;
  font-size: 1.5rem;
  width: 90%;
  margin: 1rem auto;
  font-style: normal;
  color: var(--background-2-font-color);
}
.jumbotron button {
  position: absolute;
  border-radius: 0;
  bottom: 0;
  right: 0;
  height: 4rem;
  width: 7rem;
  border-radius: 1px;
  background-color: var(--header-options-color);
  border: 0;
  color: var(--background-2-font-color);
}

.jumbotron button:hover,
.jumbotron button:active {
  background-color: var(--header-options-highlight-color);
  cursor: pointer;
}

.jumbotron button:focus {
  background-color: var(--header-options-color);
}

.jumbotron-content {
  padding-top: 35vh;
}

@media (min-width: 750px) {
  .jumbotron h1 {
    text-align: center;
    font-size: 3rem;
  }
  .jumbotron h2 {
    text-align: center;
    font-size: 2rem;
  }
}
