.toggle-button {
  width: 3rem;
  background: transparent;
  border: 2px solid #ccc;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  margin: 0.5rem 1rem;
  border-radius: 8px;
}
.toggle-button-div {
  text-align: right;
}
.toggle-button:focus {
  outline: none;
}

.toggle-button__bar {
  width: 80%;
  height: 0.1rem;
  background: #ccc;
  display: block;
  margin: 0.4rem 0;
  margin-left: 10%;
}

@media (min-width: 900px) {
  .toggle-button {
    display: none;
  }
}
