.career-list {
  background-color: var(--background-color-2);
  border-radius: 8px;
  width: 90%;
  margin: auto;
  max-width: 350px;
  padding: 1rem;
}

@media (min-width: 750px) {
  .career-list {
    width: 90%;
    max-width: 500px;
  }
}

@media (min-width: 900px) {
  .career-list {
    width: 90%;
    max-width: 900px;
  }
}
