.profile-header {
  text-align: center;
  border-bottom: 1px solid var(--border-color);
  margin: 0rem 2rem;
  padding-top: 4rem;
}
.profile-header h1 {
  font-size: 3rem;
  color: var(--card-header-font-color-1);
  font-weight: normal;
  margin: 0;
  font-family: "Zen Old Mincho", serif;
}
.profile-header p {
  font-size: 1.2rem;
  color: var(--font-color-grey);
  font-family: "Zen Old Mincho", serif;
}

.profile-header-theme-2 h1 {
  color: var(--card-header-font-color-2);
}
.profile-header-theme-2 p {
  color: var(--font-color-light-grey);
}
