.profile-content__image_container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  width: 50%;
}
.profile-content__image_container img {
  width: 100%;
  border-radius: 50%;
  border: 5px solid #ccc;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

@media (min-width: 900px) {
  .profile-content__image_container {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    margin: 2rem 1rem;
    margin-top: 5rem;
  }
}
