.project-list {
  width: 82%;
  margin: 1rem auto;
}
@media (min-width: 900px) {
  .project-list {
    width: 82%;
    max-width: 900px;
    margin: 1rem auto;
  }
}
