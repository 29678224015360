/* .project-image-card {
  position: absolute;
  height: 10rem;
  width: 100%;
}

.project-image-place-holder {
  margin-top: 5px;
  padding-bottom: 10rem;
}

.project-image-card img {
  max-width: 80%;
  max-height: 100%;
}

.project-image-card h1 {
  color: var(--background-2-font-color);
  margin: 0 0.2rem;
}

.project-image-card p {
  color: var(--background-2-font-color);
  margin: 0 0.4rem;
}

.project-image-content {
  position: absolute;
  padding-top: 2rem;
  top: 1px;
  background: var(--jumbotron-color);
  width: 100%;
  height: 8rem;
  opacity: 0;
  transition: opacity 500ms linear;
}

.project-image-content:hover {
  opacity: 1;
}

.project-image-card a {
  text-decoration: none;
  background: transparent;
} */

.project-image-card {
  width: 100%;
  object-fit: cover;
}

.project-image-card img {
  width: 100%;
  min-height: 100%;
}

.project-image-figure {
  position: relative;
  z-index: 0;
  margin: 0;
}

.project-image-content {
  background: var(--background-color-1-faded);
  position: absolute;
  height: calc(100% - 4px);
  width: 100%;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.project-image-card:hover .project-image-content,
.project-image-card:focus .project-image-content,
.project-image-card:active .project-image-content {
  opacity: 1;
}

.project-image-content h1 {
  font-size: 1.3rem;
  margin: 0.2rem;
  color: var(--font-color-grey);
  font-family: "Zen Old Mincho", serif;
}

.project-image-content h2 {
  position: absolute;
  bottom: 20%;
  margin: 0rem 1rem;
  font-size: 1rem;
  color: var(--font-color-grey);
  font-family: "Zen Old Mincho", serif;
}
@media (min-width: 900px) {
  .project-image-content h2 {
    font-size: 0.8rem;
  }
  .project-image-content h1 {
    font-size: 1rem;
  }
}
