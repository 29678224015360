.certification-card {
  padding: 1rem;
  margin: 1rem;
  transition: background-color 300ms 0s linear;
  border-radius: 8px;
  text-align: center;
}

.certification-card a {
  text-decoration: none;
  background: transparent;
}

.bottom-border {
  border-bottom: 1px solid var(--border-color);
  vertical-align: bottom;
  width: 90%;
}

.certification-card h1 {
  color: var(--background-2-font-color);
  font-size: 1rem;
  margin: 0;
}
.certification-card h2 {
  color: var(--background-2-font-color);
  font-size: 0.9rem;
  margin: 0;
}
.certification-card p {
  color: var(--font-color-light-grey);
  font-size: 0.8rem;
  margin: 0;
}

.certification-card:hover {
  background-color: var(--background-color-1);
}

.certification-card:hover p {
  color: var(--font-color-grey);
}

.certification-card:hover h2 {
  color: var(--background-1-font-color);
}
.certification-card:hover h1 {
  color: var(--background-1-font-color);
}

@media (min-width: 750px) {
  .certification-card {
    text-align: center;
    width: 70%;
    margin: 1rem auto;
  }
}

@media (min-width: 900px) {
  .certification-card {
    display: inline-block;
    text-align: left;
    width: 40%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .bottom-border {
    display: none;
  }
}
