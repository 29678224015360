.page-background {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: -1;
  background: linear-gradient(
      to top,
      var(--background-color-2-faded),
      100%,
      transparent
    ),
    url("/src/images/backgroundImage.jfif") center/cover no-repeat border-box,
    var(--background-color-2);
}
