body {
  box-sizing: border-box;
  height: 100%;
  /* font-family: var(--font-family) !important; */
}
section {
  margin-top: 3.1rem;
}

html {
  --header-bar-color: #16405b;
  --header-options-color: #16405b;
  --header-options-highlight-color: #205d86;
  --header-options-font-color: white;
  --card-header-font-color-1: #006080;
  --card-header-font-color-2: beige;
  --secondary-heading-color: #205d86;
  --font-color-grey: #777;
  --font-color-light-grey: rgba(245, 245, 245, 0.7);
  --background-color-1: rgba(245, 245, 220, 1);
  --background-color-1-faded: rgba(245, 245, 220, 0.7);
  --background-1-font-color: black;
  --background-color-2: #006080;
  --background-color-2-faded: #006080b9;
  --background-2-font-color: beige;
  --font-family: "Zen Old Mincho", serif;
  --contact-color: #103257;
  --border-color: rgb(214, 214, 55);

  height: 100%;
}

@media (min-width: 900px) {
  section {
    margin-top: 0;
  }
}
