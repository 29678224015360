.profile-content__details {
  text-align: center;
  padding-top: 2rem;
}

.profile-content__details h1 {
  font-size: 1.8rem;
  color: var(--secondary-heading-color);
  font-weight: normal;
  margin: 0;
}

.profile-content__details_info {
  margin-top: 0.5rem;
  text-align: center;
}
.profile-content__details_info h2 {
  display: block;
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}
.profile-content__details_info h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  color: var(--font-color-grey);
}
.profile-content__details_linkedIn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  width: 1rem;
}
.profile-content__details_linkedIn img {
  width: 1.5rem;
}
.profile-content__details_linkedIn img:hover,
.profile-content__details_linkedIn img:active {
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
  cursor: pointer;
}

@media (min-width: 900px) {
  .profile-content__details {
    display: inline-block;
    width: 37%;
    vertical-align: top;
    padding-top: 3rem;
  }
  .profile-content__details_info h2 {
    display: inline-block;
    width: 45%;
    text-align: right;
    padding-top: 0;
    padding-right: 0.5rem;
    border-right: 1px solid black;
    vertical-align: middle;
  }
  .profile-content__details_info h3 {
    display: inline-block;
    width: 45%;
    text-align: left;
    padding-left: 0.5rem;
    vertical-align: middle;
  }
}
