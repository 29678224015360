/* .project-text-card h1 {
  margin: 01rem 0.2rem;
  color: var(--background-2-font-color);
}

.project-text-card p {
  margin: 01rem;
  color: var(--background-2-font-color);
} */

.project-card {
  padding: 2px 0px;
}
/* 
.bottom-border {
  border-bottom: 1px solid var(--border-color);
} */
@media (min-width: 900px) {
  .project-card {
    display: inline-block;
    width: 45%;
    margin: 1rem 2.5%;
  }
}
