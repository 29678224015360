.contact-form-box {
  padding: 0.5rem;
  border-radius: 4px;
  margin: auto;
}

.control {
  margin: 1.5rem;
  text-align: left;
}

.control label {
  display: block;
  width: 80%;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  color: var(--background-2-font-color);
}

.control input {
  width: 80%;
  display: block;
  border: 2px solid #555;
  border-radius: 4px;
  padding: 0.5rem;
  font: inherit;
}
.control textarea {
  font: inherit;
  width: 80%;
  display: block;
  border: 2px solid #555;
  border-radius: 4px;
  padding: 0.5rem;
}

.contact-form button {
  width: 64%;
  margin-left: 10%;
  padding: 0.5rem;
  background-color: var(--background-color-1);
  font-weight: bold;
  border-radius: 4px;
}

.control button:hover,
.control button:active {
  background: wheat;
  cursor: pointer;
}

.form-error-message {
  color: red;
  display: block;
  text-align: right;
  width: 80%;
}

.form-message {
  color: var(--background-2-font-color);
  display: block;
  text-align: right;
  width: 80%;
}

.form-success-message {
  color: green;
  display: block;
  text-align: right;
  width: 80%;
}

@media (min-width: 750px) {
  .contact-form {
    width: 90%;
    max-width: 500px;
    margin: auto;
  }
}

@media (min-width: 900px) {
  .contact-form {
    width: 90%;
    max-width: 900px;
  }
  .control label {
    display: inline-block;
    width: 20%;
    color: var(--background-2-font-color);
    vertical-align: top;
  }

  .control input {
    width: 70%;
    display: inline-block;
    border: 2px solid #555;
    border-radius: 4px;
    padding: 0.5rem;
    font: inherit;
  }
  .control textarea {
    font: inherit;
    width: 70%;
    display: block;
    border: 2px solid #555;
    border-radius: 4px;
    padding: 0.5rem;
    display: inline-block;
  }

  .contact-form button {
    width: 10%;
    margin: 1rem 80%;
  }
  .form-error-message {
    width: 90%;
  }

  .form-message {
    width: 90%;
  }

  .form-success-message {
    width: 90%;
  }
}
