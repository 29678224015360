.certification-list {
  padding-bottom: 3rem;
  width: 90%;
  margin: auto;
  max-width: 450px;
}

@media (min-width: 750px) {
  .certification-list {
    width: 90%;
    max-width: 500px;
  }
}

@media (min-width: 900px) {
  .certification-list {
    width: 90%;
    max-width: 900px;
    padding-left: 10%;
  }
}
